define(['valumsFileUploader'], function(qq) {

  const fileUploadButton = () => {
    const component = {};

    const _init = (element) => {
      component.element = element;
      if (document.getElementById('file-uploader')) {
        const uploadService = document.getElementById('uploadService');
        const authToken = document.getElementById('authToken');
        const fileToken = document.getElementById('fileToken');
        const fileUploader = document.getElementById('file-uploader');
        window.fileUploadJSON = {};

        const uploader = new qq.FileUploader({
          element: fileUploader,
          multiple: false,
          inputName: '',
          action: uploadService.getAttribute('value'),
          customHeaders: {
            uploadToken: authToken.getAttribute('value')
          },
          sizeLimit: 1024 * 1024 * 4,
          onSubmit: function () {
          },
          onProgress: function () {
          },
          onCancel: function () {
          },
          onUpload: function () {
          },
          onError: function () {
          },
          onComplete: function (id, fileName, responseJSON) {
            window.fileUploadJSON = responseJSON;
            if (responseJSON.successful) {
              fileToken.value = responseJSON.token;
            }
          }
        });
        window.onload = uploader;
      }
    };

    component.init = _init;
    return component;
  };

  return fileUploadButton;
});
